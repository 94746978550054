<template>
    <div class="bg-white box-shadow border-r px-20 pb-20">
        <div class="d-flex-center border-bottom">
            <h4 class="mr-auto color-333">{{ $t('account.list_of_assets') }}</h4>
            <el-button type="primary" size="mini" class="mr-10" icon="el-icon-refresh" plain @click="fatchData()">{{ $t('refresh') }}</el-button>
            <span class="mr-5">{{ $t('wallet.total_value') }}</span>
            <span class="mx-5">:</span>
            <span class="color-p font-bold font-20" v-text="wallet.total_usd"></span>
            <span class="ml-5">USDT</span>
        </div>
        <el-row :gutter="10" type="flex" justify="center" align="middle" class="font-bold color-666 py-15 border-bottom font-12">
            <el-col :span='4'>{{ $t('coin') }}</el-col>
            <el-col :span='5'>{{ $t('account.available') }}</el-col>
            <el-col :span='4'>{{ $t('wallet.in_order') }}</el-col>
            <el-col :span='4'>{{ $t('wallet.value') }} (USDT)</el-col>
            <el-col class="text-right" :span='7'>{{ $t('action') }}</el-col>
        </el-row>
        <div  v-if="wallet.list.length" v-loading="loading">
            <el-row v-for="(item, index) in wallet.list" :key="index" :gutter="10" type="flex" justify="center" align="middle" class="font-bold color-333 py-15 font-14 border-bottom">
                <el-col :span='1'>
                    <el-avatar size="small" :src="coinLogo[item.coin] || item.logo" />
                </el-col>
                <el-col :span='3'>{{ item.coin }}</el-col>
                <el-col :span='5'>{{ item.available }}</el-col>
                <el-col :span='4'>{{ item.frozen }}</el-col>
                <el-col :span='4'>{{ item.usd }}</el-col>
                <el-col class="deal text-right" :span='7'>
                    <el-button type="success" icon="el-icon-circle-plus-outline" plain size='mini' @click="$nav.push('recharge/'+item.coin)">{{ $t('account.recharge') }}</el-button>
                    <el-button type="warning" icon="el-icon-remove-outline" plain size='mini' @click="$nav.push('withdraw/'+item.coin)">{{ $t('account.withdraw') }}</el-button>
                </el-col>
            </el-row>
        </div>
        <el-empty v-else :image-size="100" description="No Data"></el-empty>
        <div class="d-flex-center border-bottom mt-20">
            <h4 class="mr-auto color-333">{{ $t('account.available_log') }}</h4>
            <el-button type="primary" size="mini" icon="el-icon-refresh" plain @click="fatchRecord()">{{ $t('refresh') }}</el-button>
        </div>
        <el-row :gutter="10" type="flex" justify="center" align="middle" class="color-gray py-15 border-bottom font-12">
            <el-col :span='3'>{{ $t('coin') }}</el-col>
            <el-col :span='5'>{{ $t('amount') }}</el-col>
            <el-col :span='7'>{{ $t('account.event') }}</el-col>
            <el-col :span='5'>{{ $t('order_no') }}</el-col>
            <el-col :span='4' class="text-right">{{ $t('date_time') }}</el-col>
        </el-row>
        <div v-if="list.data.length" class="" v-loading="loadingRecord">
            <el-row v-for="(item, index) in list.data" :key="index" :gutter="20" type="flex" justify="center" align="middle" class="py-15 border-bottom font-14">
                <el-col :span='3'>{{ item.coin }}</el-col>
                <el-col :span='5'>
                    <span v-if="item.amount > 0">+</span>
                    <span>{{ item.amount }}</span>
                </el-col>
                <el-col :span='7'>{{ listType[item.type] }}</el-col>
                <el-col :span='5'><span style="overflow: hidden; word-wrap:break-word;">{{ item.order_id }}</span></el-col>
                <el-col :span='4' class="text-right">{{ item.create_time }}</el-col>
            </el-row>
        </div>
        <el-empty v-else :image-size="100" description="No Data"></el-empty>
        <div class="d-flex-center pt-20">
            <el-pagination
                :hide-on-single-page="true"
                background
                layout="prev, pager, next"
                :page-size="list.per_page"
                :current-page="list.current_page"
                :total="list.total"
                @current-change="onPage"
            />
        </div>
    </div>
</template>

<script>
import coinLogo from '@/assets/coin/logo'
export default {
    data() {
        return {
            coinLogo,
            loading: false,
            wallet: {
                list: [],
                total_usd: '0.00'
            },
            query: { ...this.$route.query },
            loadingRecord: true,
            list: {
                per_page: 1,
                current_page: 1,
                total: 0,
                data: []
            },
            listType: this.$t('wallet.log_type')
        }
    },
    created: function() {
        this.fatchData()
        this.fatchRecord()
    },
    methods: {
        fatchData: async function () {
            this.loading = true
            try {
                const { data } = await this.$request.get('wallet')
                this.wallet = data
            } catch (error) {
                //
            }
            this.loading = false
        },
        fatchRecord: async function () {
            this.loadingRecord = true
            try {
                
                const { data } = await this.$request.post('wallet/log_pc', this.query)
                this.list = data
            } catch (error) {
                //
            }
            this.loadingRecord = false
        },
        onSearch() {
            this.fatchRecord()
            this.$router.replace({
                query: this.query
            })
        },
        onPage(page) {
            this.query.page = page || 1
            this.onSearch()
        }
    }
}
</script>
<style lang="scss" scoped>
//
</style>